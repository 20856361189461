import React, { Component } from 'react';
import Firebase from 'firebase';
import config from '../../config.js';

class TestDB extends Component {
	constructor(props){
		super(props);
		Firebase.initializeApp(config);

	  this.state = {
	    name: ''
	  };
	}

  handleChange = e => {
    this.setState({
      name: e.target.value
    });
		console.log(this.state)
  };

  handleSubmit = e => {
		e.preventDefault();
	  Firebase.database().ref('/').child("name").set(this.state.name);
		alert(this.state.name);
	  console.log('DATA SAVED');
  };

  render() {
    return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<input type="text" onChange={this.handleChange} />
					<button className="submit">Submit</button>
				</form>
			</div>
    );
  }
}

export default TestDB;
