import React, { Component } from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

class About extends Component {

	constructor(props){
		super(props);
		this.state = {
			user:'',
			username:'',
			password:''
		}

		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleChange = e => {
		e.preventDefault();

		let name = e.target.name;
		let value = e.target.value;

		this.setState({
			[name]: value
		})
	}

	onSubmit = e => {
		e.preventDefault();
		let dataURL = "https://www.abhishekc.design//wp-json/jwt-auth/v1/token";
		axios.post(dataURL, {
			username: this.state.username,
			password: this.state.password
		})
		.then( res => {
			this.setState({
				user: res.data.user_nicename,
			})
			console.log(res.data.token)
		})
	}

	componentDidMount = () => {
	   document.title = "About | Abhishek C. Design";
	}

	render(){
		return(

				<div className="page">

          <MetaTags>
            <title>About | Abhishek C.</title>
            <meta name="description" content="Some description." />
            <meta name="theme-color" content="#242424" />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>

				<h1>About</h1>
				<form onSubmit={this.onSubmit}>
					<label>
						Username:
					</label>
					<input
						type="text"
						name="username"
						value={this.state.username}
						onChange={this.handleChange}
					/>
					<label>
						Password:
					</label>
					<input
						type="password"
						name="password"
						value={this.state.password}
						onChange={this.handleChange}
					/>
					<input type="submit" value="Sign In"/>
				</form>

				{!this.state.user ? (
					null
				) : (
					<div>
						<h1>{this.state.username}</h1>
						<h2>{this.state.username}</h2>
					</div>
				)}
			</div>

		)
	}
}

export default About;
