import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import About from './Component/About';
import Home from './Component/Home';
import TestDB from './Component/TestDB';
import SideMenu from './Component/SideMenu';

class App extends Component {

		render(){
			return(
				<div id="wrapper">
				<SideMenu/>
				{

				}

			<div className="page">
				<Route
						render={({ location }) => {
							const { pathname } = location;
							return(
					<TransitionGroup>
					<CSSTransition
						key={pathname}
						in='true'
						classNames="page"
						timeout={{
							enter:1000,
							exit:1000
						}}
						appear
					>
					<Route
						location={location}
						render={() =>
				<Switch>
					<Route exact={true} path={'/'} component={Home}/>
					<Route exact={true} path={'/Home'} component={Home}/>
					<Route exact={true} path={'/About'} component={About}/>
					<Route exact={true} path={'/testdb'} component={TestDB}/>
				</Switch>
			}
			/>

			</CSSTransition>
			</TransitionGroup>
		)}}
		/>
				</div>
				</div>

			)
		}
}

export default App;
