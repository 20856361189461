import React, { Component } from 'react'
import axios from 'axios';
import MetaTags from 'react-meta-tags';


class Home extends Component {
	constructor(props){
		super(props);
		this.state = {
			posts: [],
			isLoading: true,
			username:'',
			email:'',
			password:'',
			firstName:'',
			lastName:'',
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			[name]: value
		})


	}

	handleSubmit = e => {
		e.preventDefault();

		let dataURL = "https://www.abhishekc.design/wp-json/wp/v2/users/register";
		axios.post(dataURL, {
			username: this.state.username,
			password: this.state.password,
			email: this.state.email,
			firstname: this.state.firstName,
			lastname: this.state.lastName
		})
		.then(response => {
			console.log(response);
		})
	}


	componentDidMount = () => {
		let dataURL = "https://www.abhishekc.design/wp-json/wp/v2/posts?slug=hello-world";
			axios.get(dataURL, {
				method: 'get',
				credentials: 'same-origin',
				headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
			})
			.then(response => {
				this.setState({
					posts: response.data,
					isLoading: false
				})
			})
	}

	render(){
		const { isLoading } = this.state;
		let posts = this.state.posts.map((post, index) => {
			return (
				<div key={index}>
				          <MetaTags>
				            <title>{`${post.title.rendered} | Abhishek C.`}</title>
									</MetaTags>
					<p><strong>{post.title.rendered}</strong></p>
					<div dangerouslySetInnerHTML = { {__html: post.content.rendered} } >
					</div>
				</div>
			)
		})
		return(
			<div className="page">

          <MetaTags>
            <title> Abhishek C.</title>
            <meta name="description" content="Some description." />
            <meta name="theme-color" content="#242424" />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>

				<h1>Home Page</h1>
				{!isLoading ? (
					<div>
						{ posts }
					</div>
				) : (
					<h1> Loading</h1>

				)}
				<h2 className="shadow">Register</h2>
				<form onSubmit={ this.handleSubmit }>
					<label>
						Username:
					</label>
						<input
							type="text"
							name="username"
							value={this.state.username}
							onChange={this.handleChange}
						/>
					<label>
						First Name:
					</label>
						<input
							type="text"
							name="firstName"
							value={this.state.firstName}
							onChange={this.handleChange}
						/>
					<label>
						First Name:
					</label>
						<input
							type="text"
							name="lastName"
							value={this.state.lastName}
							onChange={this.handleChange}
						/>
					<label>
						Email:
					</label>
						<input
							type="text"
							name="email"
							value={this.state.email}
							onChange={this.handleChange}
						/>
					<label>
						Password:
					</label>
						<input
							type="password"
							name="password"
							value={this.state.password}
							onChange={this.handleChange}
						/>
					<input type="submit" value="Submit"/>
				</form>
			</div>
		)
	}
}

export default Home;
