import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Navigation from '../Navigation';


class SideMenu extends Component {

	constructor(){
		super();
		this.state = {
			menuOpen: false,
		}

		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.openMenu.bind(this);
	}


	openMenu = () => {
		this.setState({
			menuOpen: !this.state.menuOpen
		})
	}

	closeMenu = () => {
		console.log(this.state.menuOpen)
		this.setState({
			menuOpen: false
		})
	}

	render(){
		const { menuOpen, closeMenu } = this.state;
		return(
			<div>
				<CSSTransition
					in={menuOpen}
					timeout={100}
					className="menu"
				>
				<div id="side-menu">

					<Navigation menuClose={this.closeMenu}/>{}

				</div>
				</CSSTransition>

				<p onClick={this.openMenu}>
				{!menuOpen ? (
						<p>Open Menu</p>
				) : (
						<p>Close Menu</p>
				)}

				</p>
			</div>
		)
	}
}

export default SideMenu;
