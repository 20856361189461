import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


class Navigation extends Component {
	constructor(props){
		super(props);
		this.state = {
			menuItems: [],
		}
	}

	componentDidMount = () => {
		let dataURL = "https://www.abhishekc.design/wp-json/menus/v1/menus/16";
			axios.get(dataURL, {
				method: 'get',
				credentials: 'same-origin',
				headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
			})
			.then(response => {
				this.setState({
					menuItems: response.data.items
				})
			})
	}

	render(){
		let menuItems = this.state.menuItems.map((item, index) => {
			return(
				<li key={index}>
					<Link onClick={this.props.menuClose} to = { item.title === "Home" ? '/' : item.title }>
						{item.title}
					</Link>
				</li>
			)
		})

		return(
			<nav>
				{ menuItems }
			</nav>
		)
	}
}

export default Navigation;
